import { sheet } from "./sheet.js";
import { faker } from "@faker-js/faker";

const $categories = {
  Techniques: document.createElement("div"),
  Tools: document.createElement("div"),
  "Languages & Frameworks": document.createElement("div"),
  Platforms: document.createElement("div"),
};

export function initializeAll() {
  const $search = document.querySelector(".search-input");

  $search.addEventListener("keyup", () => {
    console.log($search.value);

    document.querySelectorAll(".card").forEach(($card) => {
      const isHit = $card.querySelector("h1").innerText.includes($search.value);
      $card.style.display = isHit ? "block" : "none";
    });
  });

  const $container = document.querySelector(".listings");

  const mapSheetRow = (row) => {
    return {
      usage: row[0],
      name: row[1],
      category: row[2],
      description: row[3] !== "" ? row[3] : null,
    };
  };

  const removeEmptyItems = (v) => {
    return v.name !== "" && v.category !== "";
  };

  sheet("all")
    .then((result) => {
      return result.data.map(mapSheetRow).filter(removeEmptyItems);
    })
    .then((data) => {
      return data.sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
    })
    .then((data) => {
      document.querySelector(".all > h1").innerText += ` (${data.length})`;

      data.forEach((item) => {
        const $card = document.createElement("div");
        $card.classList.add("card");
        $card.classList.add(item.usage.replace(" ", "-"));

        const $title = document.createElement("h1");
        $title.innerText = item.name;
        $card.appendChild($title);

        const $desc = document.createElement("p");
        $desc.innerText = item.description;
        $card.appendChild($desc);

        const count = Math.floor(Math.random() * 9) + 1;

        const $count = document.createElement("a");
        $count.classList.add("count");
        $count.href = "#";
        $count.innerText = `${count} factorials are interested`;
        $card.appendChild($count);

        const $factorials = document.createElement("ul");
        $factorials.style.display = "none";

        for (let i = 0; i < count; i++) {
          const $factorial = document.createElement("li");
          const name = faker.name.firstName();
          $factorial.innerText = `${name}, ${name.toLowerCase()}@factorial.io`;
          $factorials.appendChild($factorial);
        }
        $card.appendChild($factorials);

        $count.addEventListener("click", (ev) => {
          ev.preventDefault();
          $factorials.style.display = "block";
        });

        const $iknow = document.createElement("a");
        $iknow.href = "#iknowthis";
        $iknow.innerText = `I'm interested in ${item.name} as well`;
        $card.appendChild($iknow);

        const $blipize = document.createElement("a");
        $blipize.href = "#blipize";
        $blipize.innerText = `⭘ Should be on our radar`;
        $blipize.classList.add("blipize");
        $card.appendChild($blipize);

        $categories[item.category].appendChild($card);
      });

      Object.entries($categories).forEach(([title, $ul]) => {
        const $section = document.createElement("div");
        const $title = document.createElement("h3");
        $title.innerText = title;

        $section.appendChild($title);
        $section.appendChild($ul);
        $container.appendChild($section);
      });
    });
}

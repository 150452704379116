import Papa from "papaparse";

// Returns data for given table name. Returns unfiltered and unmapped data.
export function sheet(table) {
  const url =
    "https://docs.google.com/spreadsheets/d/e/2PACX-1vTFXL_bnY6Tx97VU0w2vxhMiuaDt6diaCuPq1n1Gn6zRdl4m2feDij82MvozNJMErmvLDfLIuQgo9z-/pub?output=csv";

  const gids = {
    radar: 0,
    // radar: 762687008,
    suggestions: 1703617344,
    all: 1121880022,
  };

  return fetch(`${url}&gid=${gids[table]}`)
    .then((res) => res.text())
    .then((csv) => Papa.parse(csv))
    .then((result) => {
      result.data.shift(); // Ignore titles.
      return result;
    });
}
